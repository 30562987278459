import React, { useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Paper,
  TextField,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Grid,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Add as AddIcon,
  WbTwilight as MorningIcon,
  DirectionsCar as CarIcon,
  Forest as ForestIcon,
  Restaurant as LunchIcon,
  Search as SearchIcon,
  PhotoCamera as CameraIcon,
  Kitchen as KitchenIcon,
  Home as HomeIcon,
} from '@mui/icons-material';
import {
  Timeline as MuiTimeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from '@mui/lab';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface TimelineEvent {
  id: string;
  time: Date;
  icon: string;
  title: string;
  content: string;
}

const availableIcons = {
  Morning: MorningIcon,
  Car: CarIcon,
  Forest: ForestIcon,
  Lunch: LunchIcon,
  Search: SearchIcon,
  Camera: CameraIcon,
  Kitchen: KitchenIcon,
  Home: HomeIcon,
};

const Timeline: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const defaultEvents: TimelineEvent[] = [
    {
      id: '1',
      time: new Date(new Date().setHours(6, 0)),
      icon: 'Morning',
      title: 'Подготовка',
      content: '- Проверьте прогноз погоды\n- Приготовьте корзину и нож\n- Возьмите воду и перекус\n- Оденьтесь по погоде'
    },
    {
      id: '2',
      time: new Date(new Date().setHours(6, 30)),
      icon: 'Car',
      title: 'Выезд',
      content: '- Проверьте маршрут\n- Заправьте машину\n- Возьмите атлас грибника'
    },
    {
      id: '3',
      time: new Date(new Date().setHours(7, 30)),
      icon: 'Forest',
      title: 'Прибытие в лес',
      content: '- Отметьте место парковки\n- Проверьте связь\n- Наметьте маршрут поиска'
    },
    {
      id: '4',
      time: new Date(new Date().setHours(8, 0)),
      icon: 'Search',
      title: 'Поиск грибов',
      content: '- Ищите в перспективных местах\n- Проверяйте каждый гриб\n- Делайте перерывы\n- Следите за временем'
    },
    {
      id: '5',
      time: new Date(new Date().setHours(11, 0)),
      icon: 'Lunch',
      title: 'Обед и отдых',
      content: '- Выберите удобное место\n- Перекусите и отдохните\n- Проверьте собранные грибы'
    }
  ];

  const [events, setEvents] = useState<TimelineEvent[]>(defaultEvents);
  const [openDialog, setOpenDialog] = useState(false);
  const [newEvent, setNewEvent] = useState<Partial<TimelineEvent>>({
    time: new Date(),
    icon: 'Morning',
    title: '',
    content: '',
  });

  const handleAddEvent = () => {
    if (newEvent.title && newEvent.content && newEvent.time && newEvent.icon) {
      const event: TimelineEvent = {
        id: Date.now().toString(),
        time: new Date(newEvent.time), 
        icon: newEvent.icon,
        title: newEvent.title,
        content: newEvent.content,
      };
      
      setEvents(prev => {
        const updated = [...prev, event];
        return updated.sort((a, b) => a.time.getTime() - b.time.getTime());
      });
      
      setOpenDialog(false);
      setNewEvent({
        time: new Date(),
        icon: 'Morning',
        title: '',
        content: '',
      });
    }
  };

  const handleDeleteEvent = (id: string) => {
    setEvents(prev => prev.filter(event => event.id !== id));
  };

  const IconComponent = (iconName: string) => {
    const Icon = availableIcons[iconName as keyof typeof availableIcons];
    return <Icon />;
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 6 }}>
        <Typography variant="h4" gutterBottom align="center">
          Мой план
        </Typography>
        
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setOpenDialog(true)}
          >
            Добавить событие
          </Button>
        </Box>

        <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="sm" fullWidth>
          <DialogTitle>Добавить новое событие</DialogTitle>
          <DialogContent>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label="Время"
                    value={dayjs(newEvent.time)}
                    onChange={(newValue) => {
                      if (newValue) {
                        const date = new Date();
                        date.setHours(newValue.hour(), newValue.minute());
                        setNewEvent(prev => ({ ...prev, time: date }));
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  fullWidth
                  label="Иконка"
                  value={newEvent.icon}
                  onChange={(e) => setNewEvent(prev => ({ ...prev, icon: e.target.value }))}
                >
                  {Object.keys(availableIcons).map((icon) => (
                    <MenuItem key={icon} value={icon}>
                      {icon}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Заголовок"
                  value={newEvent.title}
                  onChange={(e) => setNewEvent(prev => ({ ...prev, title: e.target.value }))}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Содержание"
                  value={newEvent.content}
                  onChange={(e) => setNewEvent(prev => ({ ...prev, content: e.target.value }))}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)}>Отмена</Button>
            <Button onClick={handleAddEvent} variant="contained">
              Добавить
            </Button>
          </DialogActions>
        </Dialog>

        <MuiTimeline 
            position={isMobile ? "right" : "alternate"}
            sx={{
            ...(isMobile && {
                padding: 0,
                '& .MuiTimelineItem-root': {
                    '&:before': {
                        display: 'none', // 隐藏左侧的时间显示
                    },
                    minHeight: 70,
                },
                '& .MuiTimelineContent-root': {
                    padding: '50px 16px 20px 50px', // 调整内容区域的padding
                },
                '& .MuiTimelineSeparator-root': {
                    position: 'absolute',
                    left: 0,
                },
                '& .MuiTimelineDot-root': {
                    margin: '10px 0',
                },
                '& .MuiTimelineConnector-root': {
                    left: '20px',
                },
                '& .MuiTimelineOppositeContent-root': {
                    position: 'absolute',
                    left: '40px',
                    flex: 'none',
                    width: 'auto',
                }
            }),
            }}
        >
          {events.map((event, index) => (
            <TimelineItem key={event.id}>
              <TimelineOppositeContent color="text.secondary">
                {event.time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="primary">
                  {IconComponent(event.icon)}
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper 
                  elevation={3} 
                  sx={{ 
                    p: 2,
                    position: 'relative',
                    minHeight: '80px',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'flex-end', mb: 1 }}>
                    {!isMobile && index % 2 === 1 && (
                      <IconButton
                        size="small"
                        onClick={() => handleDeleteEvent(event.id)}
                        sx={{ mr: 1 }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                    <Typography variant="h6" sx={{ flex: 1 }}>
                      {event.title}
                    </Typography>
                    {(isMobile || (!isMobile && index % 2 === 0)) && (
                      <IconButton
                        size="small"
                        onClick={() => handleDeleteEvent(event.id)}
                        sx={{ ml: 1 }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Box>
                  <Typography style={{ whiteSpace: 'pre-line' }}>
                    {event.content}
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          ))}
        </MuiTimeline>
      </Box>
    </Container>
  );
};

export default Timeline;