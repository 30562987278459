import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#8B4513', // 红褐色
      light: '#A0522D',
      dark: '#654321',
    },
    secondary: {
      main: '#CD853F', // 秋褐色
      light: '#DEB887',
      dark: '#8B7355',
    },
    background: {
      default: '#F5F5DC', // 米色背景
      paper: '#FFF8DC', // 玉米色纸张背景
    },
    text: {
      primary: '#4A3728', // 深褐色文字
      secondary: '#6B4423', // 中褐色文字
    },
    error: {
      main: '#8B0000', // 深红色
    },
    success: {
      main: '#2E8B57', // 深绿色
    },
  },
  typography: {
    fontFamily: [
      'Crimson Text',
      'Georgia',
      'serif',
    ].join(','),
    h1: {
      fontSize: '2.5rem',
      fontWeight: 600,
      color: '#4A3728',
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 600,
      color: '#4A3728',
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 600,
      color: '#4A3728',
    },
    body1: {
      fontSize: '1rem',
      color: '#4A3728',
    },
    body2: {
      fontSize: '0.875rem',
      color: '#6B4423',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#8B4513',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#FFF8DC',
          borderRight: '1px solid #DEB887',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          textTransform: 'none',
          padding: '8px 24px',
          fontSize: '1rem',
        },
        contained: {
          backgroundColor: '#8B4513',
          color: '#FFF8DC',
          '&:hover': {
            backgroundColor: '#A0522D',
          },
        },
        outlined: {
          borderColor: '#8B4513',
          color: '#8B4513',
          '&:hover': {
            borderColor: '#A0522D',
            backgroundColor: 'rgba(139, 69, 19, 0.04)',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFF8DC',
          borderRadius: 16,
          boxShadow: '0 4px 8px rgba(139, 69, 19, 0.15)',
          border: '1px solid #DEB887',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'rgba(139, 69, 19, 0.08)',
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: '#8B4513',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFF8DC',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#CD853F',
            },
            '&:hover fieldset': {
              borderColor: '#8B4513',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#8B4513',
            },
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          backgroundColor: 'rgba(46, 139, 87, 0.1)',
          color: '#2E8B57',
        },
        standardError: {
          backgroundColor: 'rgba(139, 0, 0, 0.1)',
          color: '#8B0000',
        },
      },
    },
  },
});

export default theme;
