import React, { useState } from 'react';
import { Box, Grid, Card, CardContent, Typography, Container } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import GoogleMapReact from 'google-map-react';

// Mock weather data
const mockWeather = {
  temperature: 22,
  condition: 'Хорошо',
  humidity: 65,
  windSpeed: 3.5
};

interface MarkerProps {
  lat: number;
  lng: number;
}

const Marker: React.FC<MarkerProps> = () => (
  <div style={{
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    color: 'red',
    fontSize: '2rem'
  }}>
    📍
  </div>
);

const LocationPlan: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs().add(1, 'day'));
  const [selectedLocation, setSelectedLocation] = useState({
    lat: 39.9042,
    lng: 116.4074,
    address: 'Санкт-Петербург, Россия'
  });

  const defaultMapProps = {
    center: {
      lat: 59.937500,
      lng: 30.308611
    },
    zoom: 11
  };

  const handleMapClick = ({ lat, lng }: { lat: number; lng: number }) => {
    setSelectedLocation({
      lat,
      lng,
      address: `Латитуда: ${lat.toFixed(4)}, Долгота: ${lng.toFixed(4)}`
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card sx={{ mb: 3 }}>
              <CardContent>
                <DatePicker
                  label="Выберите дату"
                  value={selectedDate}
                  onChange={(newValue) => newValue && setSelectedDate(newValue)}
                  sx={{ width: '100%' }}
                />
              </CardContent>
            </Card>
            
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Выбранное местоположение
                </Typography>
                <Typography variant="body1">
                  Местоположение: {selectedLocation.address}
                </Typography>
                <Typography variant="h6" sx={{ mt: 2 }}>
                  Погодные условия
                </Typography>
                <Typography variant="body1">
                  Температура: {mockWeather.temperature}°C
                </Typography>
                <Typography variant="body1">
                  Погода: {mockWeather.condition}
                </Typography>
                <Typography variant="body1">
                  Влажность: {mockWeather.humidity}%
                </Typography>
                <Typography variant="body1">
                  Скорость ветра: {mockWeather.windSpeed} m/s
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={12} md={8}>
            <Card sx={{ height: '600px' }}>
              <Box sx={{ height: '100%' }}>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: '' }}
                  defaultCenter={defaultMapProps.center}
                  defaultZoom={defaultMapProps.zoom}
                  onClick={handleMapClick}
                >
                  <Marker
                    lat={selectedLocation.lat}
                    lng={selectedLocation.lng}
                  />
                </GoogleMapReact>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </LocalizationProvider>
  );
};

export default LocationPlan;