import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ForagingIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M12,3c0,0-6.186,5.34-6.186,11.51c0,3.699,2.486,6.59,6.186,6.59 c3.699,0,6.186-2.891,6.186-6.59C18.186,8.34,12,3,12,3z M12,19.66c-2.804,0-4.986-2.136-4.986-5.15 c0-4.317,3.747-8.387,4.986-9.641c1.239,1.254,4.986,5.324,4.986,9.641C16.986,17.524,14.804,19.66,12,19.66z" />
    <path d="M12,10c-2.209,0-4,1.791-4,4s1.791,4,4,4s4-1.791,4-4S14.209,10,12,10z" />
  </SvgIcon>
);

export const PhotoCaptureIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M12,10l-3.5,3.5l1.4,1.4l2.1-2.1l2.1,2.1l1.4-1.4L12,10z" />
    <path d="M20,4h-3.17L15,2H9L7.17,4H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V6C22,4.9,21.1,4,20,4z M20,18H4V6h4.05 l1.83-2h4.24l1.83,2H20V18z" />
    <circle cx="12" cy="12" r="3" />
  </SvgIcon>
);

export const RecordsIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M14,10H2v2h12V10z M14,6H2v2h12V6z M2,16h8v-2H2V16z M21.5,11.5L23,13l-6.99,7l-4.51-4.5L13,14l3.01,3L21.5,11.5z" />
  </SvgIcon>
);

export const TimelineIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M23,8c0,1.1-0.9,2-2,2c-0.18,0-0.35-0.02-0.51-0.07l-3.56,3.55C16.98,13.64,17,13.82,17,14c0,1.1-0.9,2-2,2s-2-0.9-2-2 c0-0.18,0.02-0.36,0.07-0.52l-2.55-2.55C10.36,10.98,10.18,11,10,11s-0.36-0.02-0.52-0.07l-4.55,4.56C4.98,15.65,5,15.82,5,16 c0,1.1-0.9,2-2,2s-2-0.9-2-2s0.9-2,2-2c0.18,0,0.35,0.02,0.51,0.07l4.56-4.55C8.02,9.36,8,9.18,8,9c0-1.1,0.9-2,2-2s2,0.9,2,2 c0,0.18-0.02,0.36-0.07,0.52l2.55,2.55C14.64,12.02,14.82,12,15,12s0.36,0.02,0.52,0.07l3.55-3.56C19.02,8.35,19,8.18,19,8 c0-1.1,0.9-2,2-2S23,6.9,23,8z M23,12v8c0,1.1-0.9,2-2,2H3c-1.1,0-2-0.9-2-2v-8h2v8h18v-8H23z"/>
  </SvgIcon>
);

export const LocationPlanIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z" />
    <circle cx="12" cy="9" r="2.5" />
  </SvgIcon>
);

export const HomeIcons = {
  ForagingIcon,
  PhotoCaptureIcon,
  RecordsIcon,
  TimelineIcon,
  LocationPlanIcon,
};

export default HomeIcons;
