import React from 'react';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Box,
  Button,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ForagingIcon, PhotoCaptureIcon, RecordsIcon, TimelineIcon, LocationPlanIcon } from '../components/HomeIcons';

const Home: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const iconStyle = {
    width: '100%',
    height: 200,
    color: theme.palette.primary.main,
    padding: 3,
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant="h3" component="h1" gutterBottom align="center">
          Добро пожаловать в Family Foraging
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom align="center" color="textSecondary">
          Ваш семейный помощник по сбору грибов
        </Typography>
      </Box>

      <Grid container spacing={2} sx={{ px: { xs: 2, md: 8 }, py: 4 }}>
        <Grid item xs={12} md={4}>
          <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ p: 2, bgcolor: 'background.default' }}>
              <ForagingIcon sx={iconStyle} />
            </Box>
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography gutterBottom variant="h5" component="h2">
                Информации о сборе
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Узнайте о лучших местах и методах сбора грибов. Советы от опытных грибников.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
                onClick={() => navigate('/foraging')}
              >
                Подробнее
              </Button>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ p: 2, bgcolor: 'background.default' }}>
              <TimelineIcon sx={iconStyle} />
            </Box>
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography gutterBottom variant="h5" component="h2">
                План похода
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Создайте и управляйте планом вашего похода за грибами.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
                onClick={() => navigate('/timeline')}
              >
                Открыть план
              </Button>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ p: 2, bgcolor: 'background.default' }}>
              <LocationPlanIcon sx={iconStyle} />
            </Box>
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography gutterBottom variant="h5" component="h2">
                Выбрать место
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Выберите место и время, проверьте погодные условия.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
                onClick={() => navigate('/location-plan')}
              >
                Выбрать
              </Button>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ p: 2, bgcolor: 'background.default' }}>
              <PhotoCaptureIcon sx={iconStyle} />
            </Box>
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography gutterBottom variant="h5" component="h2">
                Добавить запись
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Сфотографируйте грибы и добавьте их в свою коллекцию записей по датам.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
                onClick={() => navigate('/collection')}
              >
                Добавить запись
              </Button>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ p: 2, bgcolor: 'background.default' }}>
              <RecordsIcon sx={iconStyle} />
            </Box>
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography gutterBottom variant="h5" component="h2">
                Записи
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Просматривайте историю ваших находок по датам и местам.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
                onClick={() => navigate('/my-records')}
              >
                Просмотреть записи
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Home;
