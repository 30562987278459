import React, { useState, useRef } from 'react';
import {
  Box,
  Button,
  Container,
  Paper,
  Typography,
  Divider,
  IconButton,
  Fab,
  Snackbar,
  Alert,
  CircularProgress,
  TextField
} from '@mui/material';
import {
  PhotoCamera as CameraIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  HelpOutline as HelpOutlineIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

interface MushroomInfo {
  name: string;
  species: string;
  isEdible: boolean;
  description: string;
}

interface RecordData {
  location: string;
  notes: string;
  date: string;
}

const Collection: React.FC = () => {
  const navigate = useNavigate();
  const [photos, setPhotos] = useState<string[]>([]);
  const [selectedPhoto, setSelectedPhoto] = useState<number>(0);
  const [mushroomInfo, setMushroomInfo] = useState<MushroomInfo | null>(null);
  const [loading, setLoading] = useState(false);
  const [recordData, setRecordData] = useState<RecordData>({
    location: '',
    notes: '',
    date: new Date().toISOString().split('T')[0],
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error',
  });
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handlePhotoCapture = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhotos([...photos, reader.result as string]);
        // 模拟识别过程
        setLoading(true);
        setTimeout(() => {
          const mushrooms = [
            {
              name: 'Белый гриб',
              species: 'Boletus edulis',
              isEdible: true,
              description: 'Крупный гриб с коричневой шляпкой и белой ножкой. Один из самых ценных съедобных грибов.',
            },
            {
              name: 'Лисички',
              species: 'Cantharellus cibarius',
              isEdible: true,
              description: 'Яркие желто-оранжевые грибы с воронковидной шляпкой. Очень вкусные и полезные.',
            },
            {
              name: 'Подберёзовик',
              species: 'Leccinum scabrum',
              isEdible: true,
              description: 'Гриб с серо-коричневой шляпкой и белой ножкой с черными чешуйками. Хороший съедобный гриб.',
            },
            {
              name: 'Мухомор красный',
              species: 'Amanita muscaria',
              isEdible: false,
              description: 'Ядовитый гриб с красной шляпкой в белых крапинках. Не употреблять в пищу!',
            },
          ];

          // Случайный выбор гриба для демонстрации
          const randomMushroom = mushrooms[Math.floor(Math.random() * mushrooms.length)];
          setMushroomInfo(randomMushroom);
          setLoading(false);
        }, 1500);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeletePhoto = (index: number) => {
    const newPhotos = photos.filter((_, i) => i !== index);
    setPhotos(newPhotos);
    if (selectedPhoto >= newPhotos.length) {
      setSelectedPhoto(Math.max(0, newPhotos.length - 1));
    }
    if (newPhotos.length === 0) {
      setMushroomInfo(null);
    }
  };

  const handleRecordDataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setRecordData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleAddToCollection = () => {
    // TODO: Implement API call to save to collection
    const record = {
      ...recordData,
      photo: photos[selectedPhoto],
      mushroomInfo,
    };
    console.log('Saving record:', record);

    setSnackbar({
      open: true,
      message: 'Успешно добавлено в записи!',
      severity: 'success',
    });

    // Navigate to records page after successful save
    setTimeout(() => {
      navigate('/my-records');
    }, 1500);
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" gutterBottom align="center">
          Добавить запись гриба
        </Typography>

        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            minHeight: '70vh',
          }}
        >
          {/* Left Side - Photos */}
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              p: 2,
            }}
          >
            <input
              type="file"
              accept="image/*"
              capture="environment"
              onChange={handlePhotoCapture}
              ref={fileInputRef}
              style={{ display: 'none' }}
            />

            {photos.length === 0 ? (
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Fab
                  color="primary"
                  aria-label="take photo"
                  onClick={() => fileInputRef.current?.click()}
                  sx={{ width: 80, height: 80, mb: 2 }}
                >
                  <CameraIcon sx={{ fontSize: 40 }} />
                </Fab>
                <Typography variant="h6" color="text.secondary">
                  Нажмите, чтобы сфотографировать гриб
                </Typography>
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    width: '100%',
                    height: '400px',
                    position: 'relative',
                    mb: 2,
                  }}
                >
                  <img
                    src={photos[selectedPhoto]}
                    alt="гриб"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                  />
                  <IconButton
                    sx={{
                      position: 'absolute',
                      top: 15,
                      right: 10,
                      backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    }}
                    onClick={() => handleDeletePhoto(selectedPhoto)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                  {photos.map((photo, index) => (
                    <Box
                      key={index}
                      sx={{
                        width: 80,
                        height: 80,
                        cursor: 'pointer',
                        border: index === selectedPhoto ? '2px solid primary.main' : 'none',
                      }}
                      onClick={() => setSelectedPhoto(index)}
                    >
                      <img
                        src={photo}
                        alt={`миниатюра ${index}`}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                    </Box>
                  ))}
                  <Fab
                    color="primary"
                    size="small"
                    onClick={() => fileInputRef.current?.click()}
                    sx={{ width: 80, height: 80 }}
                  >
                    <AddIcon />
                  </Fab>
                </Box>
              </>
            )}
          </Box>

          <Divider orientation="vertical" flexItem sx={{ mx: 2, display: { xs: 'none', md: 'block' } }} />
          <Divider sx={{ my: 2, display: { xs: 'block', md: 'none' } }} />

          {/* Right Side - Info */}
          <Box
            sx={{
              flex: 1,
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : photos.length > 0 ? (
              <>
                {mushroomInfo && (
                  <>
                    <Typography variant="h5" gutterBottom>
                      {mushroomInfo.name}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                      {mushroomInfo.species}
                    </Typography>
                    <Alert
                      severity={mushroomInfo.isEdible ? 'success' : 'error'}
                      sx={{ my: 2 }}
                    >
                      {mushroomInfo.isEdible ? 'Съедобный' : 'Ядовитый'}
                    </Alert>
                    <Typography variant="body1" paragraph>
                      {mushroomInfo.description}
                    </Typography>

                    <Box sx={{ mt: 2 }}>
                      <TextField
                        fullWidth
                        label="Место сбора"
                        name="location"
                        value={recordData.location}
                        onChange={handleRecordDataChange}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label="Дата"
                        type="date"
                        name="date"
                        value={recordData.date}
                        onChange={handleRecordDataChange}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        fullWidth
                        label="Заметки"
                        name="notes"
                        value={recordData.notes}
                        onChange={handleRecordDataChange}
                        margin="normal"
                        multiline
                        rows={4}
                      />
                    </Box>

                    <Box sx={{ mt: 2 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleAddToCollection}
                        startIcon={<AddIcon />}
                      >
                        Добавить в записи
                      </Button>
                    </Box>
                  </>
                )}
              </>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                <Fab
                  color="primary"
                  aria-label="info"
                  sx={{ width: 80, height: 80, mb: 2 }}
                >
                  <HelpOutlineIcon sx={{ fontSize: 40 }} />
                </Fab>
                <Typography variant="h6" color="text.secondary">
                  Сфотографируйте гриб, чтобы получить информацию
                </Typography>
              </Box>
            )}
          </Box>
        </Paper>
      </Box>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Collection;
