import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Card,
  CardMedia,
  CardContent,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Alert,
  TextField,
  InputAdornment,
  Pagination,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  Search as SearchIcon,
} from '@mui/icons-material';

interface MushroomRecord {
  id: number;
  name: string;
  species: string;
  isEdible: boolean;
  description: string;
  imageUrl: string;
  date: string;
}

const MyRecords: React.FC = () => {
  // 模拟数据
  const [records, setRecords] = useState<MushroomRecord[]>([
    {
      id: 1,
      name: 'Белый гриб',
      species: 'Boletus edulis',
      isEdible: true,
      description: 'Крупный гриб с коричневой шляпкой и белой ножкой. Один из самых ценных съедобных грибов.',
      imageUrl: 'https://www.diet-health.info/images/recipes/main_view/gemeiner-steinpilz-boletus-edulis.jpg',
      date: '2024-09-15',
    },
    {
      id: 2,
      name: 'Подберёзовик',
      species: 'Leccinum scabrum',
      isEdible: true,
      description: 'Съедобный гриб, растущий под берёзами. Шляпка коричневая, ножка с чёрными чешуйками.',
      imageUrl: 'https://dolina-sad.ru/upload/medialibrary/6c9/2qdtm1av3xotpmwzegdljc95ofkyb8ks.png',
      date: '2024-09-14',
    },
    {
      id: 3,
      name: 'Лисички',
      species: 'Cantharellus cibarius',
      isEdible: true,
      description: 'Яркие желто-оранжевые грибы с воронковидной шляпкой. Очень вкусные и полезные.',
      imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Chanterelle_Cantharellus_cibarius.jpg/1200px-Chanterelle_Cantharellus_cibarius.jpg',
      date: '2024-09-13',
    },
    {
      id: 4,
      name: 'Опята',
      species: 'Armillaria mellea',
      isEdible: true,
      description: 'Растут группами на пнях и стволах деревьев. Медово-коричневые шляпки с чешуйками.',
      imageUrl: 'https://cdnn21.img.ria.ru/images/07e6/05/17/1790237032_0:53:2849:1656_1920x0_80_0_0_001cdb2e82b3f12fd22f5ce5416f4d09.jpg',
      date: '2024-09-12',
    },
    {
      id: 5,
      name: 'Мухомор красный',
      species: 'Amanita muscaria',
      isEdible: false,
      description: 'Ядовитый гриб с красной шляпкой в белых крапинках. Не употреблять в пищу!',
      imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSB5LwYU3EO72djnnNlhqWobuRlMDQWV9igjA&s',
      date: '2024-09-11',
    },
    {
      id: 6,
      name: 'Подосиновик',
      species: 'Leccinum aurantiacum',
      isEdible: true,
      description: 'Съедобный гриб с красно-оранжевой шляпкой. Часто растет рядом с осинами.',
      imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/d/d8/Leccinum_versipelle_3.jpg',
      date: '2024-09-10',
    },
    {
      id: 7,
      name: 'Сыроежка',
      species: 'Russula',
      isEdible: true,
      description: 'Разноцветные грибы с хрупкой мякотью. Большинство видов съедобны.',
      imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/8/84/Russula_betularum_01.jpg',
      date: '2024-09-09',
    },
    {
      id: 8,
      name: 'Волнушка',
      species: 'Lactarius torminosus',
      isEdible: true,
      description: 'Розоватый гриб с волокнистым краем шляпки. Требует длительной обработки.',
      imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/8/87/Lactarius_torminosus_041031w.jpg',
      date: '2024-09-08',
    },
    {
      id: 9,
      name: 'Рыжик',
      species: 'Lactarius deliciosus',
      isEdible: true,
      description: 'Оранжевый гриб с зеленеющими повреждениями. Очень вкусный.',
      imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQr7d8_t3rktZ3yt7RaNjFHP5oOV1K37rT6tQ&s',
      date: '2024-09-07',
    },
    {
      id: 10,
      name: 'Груздь',
      species: 'Lactarius resimus',
      isEdible: true,
      description: 'Белый гриб с завернутыми краями. Отличный гриб для засолки.',
      imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Lactarius_resimus1.jpg/640px-Lactarius_resimus1.jpg',
      date: '2024-09-06',
    },
    {
      id: 11,
      name: 'Бледная поганка',
      species: 'Amanita phalloides',
      isEdible: false,
      description: 'Смертельно ядовитый гриб! Зеленоватая шляпка, белая ножка с кольцом.',
      imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/1/12/2005-09_Amanita_phalloides_crop.jpg',
      date: '2024-09-05',
    },
    {
      id: 12,
      name: 'Масленок',
      species: 'Suillus luteus',
      isEdible: true,
      description: 'Съедобный гриб со слизистой коричневой шляпкой. Растет в сосновых лесах.',
      imageUrl: 'https://ourreg.ru/wp-content/uploads/2023/11/a289648133c89a5a517a973c28f1e25d.jpg',
      date: '2024-09-04',
    }
  ]);

  const [selectedRecord, setSelectedRecord] = useState<MushroomRecord | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const recordsPerPage = 6; // 每页显示6条记录

  const handleDelete = (record: MushroomRecord) => {
    setSelectedRecord(record);
    setDeleteDialogOpen(true);
  };

  const handleViewDetails = (record: MushroomRecord) => {
    setSelectedRecord(record);
    setDetailsDialogOpen(true);
  };

  const confirmDelete = () => {
    if (selectedRecord) {
      setRecords(records.filter((r) => r.id !== selectedRecord.id));
    }
    setDeleteDialogOpen(false);
  };

  const filteredRecords = records.filter(record =>
    record.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    record.species.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // 计算当前页的记录
  const indexOfLastRecord = page * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredRecords.slice(indexOfFirstRecord, indexOfLastRecord);

  // 处理页面变化
  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    window.scrollTo(0, 0); // 回到顶部
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" gutterBottom align="center">
          Мои записи о грибах
        </Typography>

        <Box sx={{ mb: 4 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Поиск по названию или виду"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Grid container spacing={3}>
          {currentRecords.map((record) => (
            <Grid item xs={12} sm={6} md={4} key={record.id}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'relative',
                }}
              >
                <CardMedia
                  component="img"
                  height="200"
                  image={record.imageUrl}
                  alt={record.name}
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    {record.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {record.species}
                  </Typography>
                  <Alert
                    severity={record.isEdible ? 'success' : 'error'}
                    sx={{ mt: 1 }}
                  >
                    {record.isEdible ? 'Съедобный' : 'Ядовитый'}
                  </Alert>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      mt: 2,
                    }}
                  >
                    <Typography variant="body2" color="text.secondary">
                      Дата сбора: {record.date}
                    </Typography>
                    <Box>
                      <IconButton
                        size="small"
                        onClick={() => handleViewDetails(record)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => handleDelete(record)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* 分页控件 */}
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
          <Pagination
            count={Math.ceil(filteredRecords.length / recordsPerPage)}
            page={page}
            onChange={handlePageChange}
            color="primary"
            size="large"
            showFirstButton
            showLastButton
          />
        </Box>

        {/* Delete Confirmation Dialog */}
        <Dialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
        >
          <DialogTitle>Подтверждение удаления</DialogTitle>
          <DialogContent>
            <Typography>
              Вы уверены, что хотите удалить запись "{selectedRecord?.name}"? Это действие невозможно отменить.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialogOpen(false)}>Отмена</Button>
            <Button onClick={confirmDelete} color="error">
              Удалить
            </Button>
          </DialogActions>
        </Dialog>

        {/* Details Dialog */}
        <Dialog
          open={detailsDialogOpen}
          onClose={() => setDetailsDialogOpen(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>{selectedRecord?.name}</DialogTitle>
          <DialogContent>
            <Box sx={{ mb: 2 }}>
              <img
                src={selectedRecord?.imageUrl}
                alt={selectedRecord?.name}
                style={{ width: '100%', maxHeight: '400px', objectFit: 'contain' }}
              />
            </Box>
            <Typography variant="h6" gutterBottom>
              Информация о виде
            </Typography>
            <Typography variant="body2" paragraph>
              Научное название: {selectedRecord?.species}
            </Typography>
            <Alert
              severity={selectedRecord?.isEdible ? 'success' : 'error'}
              sx={{ mb: 2 }}
            >
              {selectedRecord?.isEdible ? 'Съедобный' : 'Ядовитый'}
            </Alert>
            <Typography variant="h6" gutterBottom>
              Подробное описание
            </Typography>
            <Typography variant="body2" paragraph>
              {selectedRecord?.description}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Дата сбора: {selectedRecord?.date}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDetailsDialogOpen(false)}>Закрыть</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Container>
  );
};

export default MyRecords;
