import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import Layout from './components/Layout';

// Pages
import Home from './pages/Home';
import Foraging from './pages/Foraging';
import Collection from './pages/Collection';
import Login from './pages/Login';
import Register from './pages/Register';
import MyRecords from './pages/MyRecords';
import Timeline from './pages/Timeline';
import LocationPlan from './pages/LocationPlan';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/foraging" element={<Foraging />} />
            <Route path="/collection" element={<Collection />} />
            <Route path="/my-records" element={<MyRecords />} />
            <Route path="/timeline" element={<Timeline />} />
            <Route path="/location-plan" element={<LocationPlan />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
          </Routes>
        </Layout>
      </Router>
    </ThemeProvider>
  );
};

export default App;
