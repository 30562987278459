import React from 'react';
import { 
  Typography, 
  Container, 
  Paper, 
  Grid, 
  Box,
  Card,
  CardContent,
  CardMedia,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import {
  CheckCircle as CheckCircleIcon,
  Warning as WarningIcon,
  Cloud as CloudIcon,
  AccessTime as TimeIcon,
  Nature as NatureIcon,
  LocalOffer as TagIcon,
  GpsFixed as LocationIcon,
  Forest as ForestIcon,
  Kitchen as KitchenIcon,
} from '@mui/icons-material';

const Foraging: React.FC = () => {
  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 6 }}>
        <Typography variant="h4" gutterBottom align="center">
          Информации о сборе грибов
        </Typography>
        
        {/* Основные рекомендации */}
        <Paper sx={{ p: 3, mb: 4 }}>
          <Typography variant="h5" gutterBottom color="primary">
            Основные правила сбора грибов
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="success" />
              </ListItemIcon>
              <ListItemText 
                primary="Собирайте только известные грибы"
                secondary="Если сомневаетесь в грибе - лучше его не брать"
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="success" />
              </ListItemIcon>
              <ListItemText 
                primary="Используйте правильную корзину"
                secondary="Корзина или плетеная сумка позволяет спорам распространяться"
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <WarningIcon color="error" />
              </ListItemIcon>
              <ListItemText 
                primary="Избегайте загрязненных мест"
                secondary="Не собирайте грибы вдоль дорог и в промышленных зонах"
              />
            </ListItem>
          </List>
        </Paper>

        {/* Необходимое снаряжение */}
        <Paper sx={{ p: 3, mb: 4 }}>
          <Typography variant="h5" gutterBottom color="primary">
            Необходимое снаряжение
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Card>
                <CardMedia
                  component="img"
                  height="200"
                  image="https://upload.wikimedia.org/wikipedia/commons/thumb/6/69/Knitted_basket1.jpg/440px-Knitted_basket1.jpg"
                  alt="Корзина для грибов"
                />
                <CardContent>
                  <Typography variant="h6">Корзина</Typography>
                  <Typography variant="body2" color="text.secondary">
                    Плетеная корзина или сумка для правильной вентиляции
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardMedia
                  component="img"
                  height="200"
                  image="https://www.knife-vorsma.ru/wa-data/public/shop/products/54/85/8554/images/36998/36998.580@2x.jpg"
                  alt="Нож для грибов"
                />
                <CardContent>
                  <Typography variant="h6">Нож</Typography>
                  <Typography variant="body2" color="text.secondary">
                    Специальный нож для аккуратного срезания грибов
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardMedia
                  component="img"
                  height="200"
                  image="https://static10.labirint.ru/books/234794/scrn_big_1.jpg"
                  alt="Атлас грибов"
                />
                <CardContent>
                  <Typography variant="h6">Атлас грибов</Typography>
                  <Typography variant="body2" color="text.secondary">
                    Справочник для определения видов грибов
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Paper>

        {/* Полезные советы */}
        <Paper sx={{ p: 3 }}>
          <Typography variant="h5" gutterBottom color="primary">
            Полезные советы
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <TimeIcon color="info" />
              </ListItemIcon>
              <ListItemText 
                primary="Лучшее время для сбора"
                secondary="Раннее утро, с 6 до 10 часов. В это время грибы свежие, хорошо заметны и меньше вероятность, что их уже собрали другие грибники."
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <CloudIcon color="info" />
              </ListItemIcon>
              <ListItemText 
                primary="Погодные условия"
                secondary="Оптимальное время - через 2-3 дня после обильного дождя при температуре 15-25°C. Влажная почва и умеренная температура способствуют росту грибов."
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <LocationIcon color="success" />
              </ListItemIcon>
              <ListItemText 
                primary="Выбор места"
                secondary="Ищите грибы в смешанных лесах с преобладанием берёз, дубов и сосен. Избегайте мест рядом с промышленными зонами и автомагистралями."
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <NatureIcon color="success" />
              </ListItemIcon>
              <ListItemText 
                primary="Особенности ландшафта"
                secondary="Обращайте внимание на влажные низины, края полян, места около поваленных деревьев. Разные виды грибов предпочитают разные условия."
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <TagIcon color="warning" />
              </ListItemIcon>
              <ListItemText 
                primary="Признаки хороших грибов"
                secondary="Выбирайте крепкие, неповрежденные грибы без признаков старения. Молодые грибы вкуснее и безопаснее старых."
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <ForestIcon color="success" />
              </ListItemIcon>
              <ListItemText 
                primary="Правильный сбор"
                secondary="Срезайте гриб острым ножом у основания ножки. Не разрушайте грибницу, не раскапывайте лесную подстилку."
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <KitchenIcon color="warning" />
              </ListItemIcon>
              <ListItemText 
                primary="Обработка и хранение"
                secondary="Очистите грибы от мусора сразу в лесу. Обработайте в день сбора: отварите, пожарьте или засолите. Не храните свежие грибы больше суток."
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <WarningIcon color="error" />
              </ListItemIcon>
              <ListItemText 
                primary="Меры предосторожности"
                secondary="Никогда не пробуйте сырые грибы. При малейшем сомнении в съедобности гриба - оставьте его в лесу. Лучше перестраховаться, чем рисковать здоровьем."
              />
            </ListItem>
          </List>
        </Paper>
      </Box>
    </Container>
  );
};

export default Foraging;
